<template>
  <v-container fluid grid-list-md>
    <AppTitleBar>
      <template v-slot:title v-if="loading">{{ $t('labels.client') }}</template>
      <template v-slot:title v-else>{{ client.display }}</template>
      <template slot="actions">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="dismiss" v-on="on">
              <v-icon small>{{ $vuetify.icons.values.viewBack }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.clients') }}</span>
        </v-tooltip>
        <v-btn icon @click="doDelete(item)">
          <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
        </v-btn>
      </template>
    </AppTitleBar>

    <v-loading v-if="loading" />
    <v-row v-else>
      <v-col cols="12">
        <v-tabs v-model="activeTab" slider-color="grey darken-1">
          <v-tab ripple>{{ $t('labels.overview') }}</v-tab>
          <v-tab-item class="mt-4">
            <ClientWidget :client-id="client.id" :read-only="!$client.isAllowed('update', client)" />
          </v-tab-item>

          <v-tab ripple>{{ $t('labels.deals') }}</v-tab>
          <v-tab-item class="mt-4">
            <ClientDeals :client-id="client.id" />
          </v-tab-item>

          <v-tab ripple>{{ $t('labels.notes') }}</v-tab>
          <v-tab-item class="mt-4">
            <ClientNotes :client-id="client.id" />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { AppTitleBar, VLoading } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
import clientPerimeter from '../perimeters'
import { ClientWidget, ClientDeals, ClientNotes } from '../components'

export default {
  name: 'ClientPage',
  components: {
    AppTitleBar,
    ClientWidget,
    ClientDeals,
    ClientNotes,
    VLoading
  },
  perimeters: [clientPerimeter],
  mixins: [Modal],
  props: {
    clientId: { type: [Number, String], required: true }
  },
  data: () => ({
    activeTab: null,
    returnPath: { name: 'clients' },
    loading: true
  }),
  computed: {
    ...mapState(NAMESPACE, ['client'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getClient', 'deleteClient', 'clearClient'])
  },
  created() {
    this.getClient(this.clientId).then(() => {
      this.loading = false
    })
  },
  destroyed() {
    this.clearClient()
  }
}
</script>

<style scoped>
.tools__area {
  min-height: 200px;
}
</style>
